export type TaxType = 'Inclusive' | 'Exclusive';

export interface LineItem {
  variantId: number;
  quantity: number;
  promotion?: Promotion;
  price: number;
  priceExclusive: number;
  taxType: TaxType;
  lineItemId: number;
  xLineItemQuantities?: LineItemQuantities[];
  categoryIds: number[];
}

export interface LineItemPromotion {
  lineItem: LineItem;
  promotionDetails: PromotionDetails;
}

export interface PromotionDetails {
  name: string;
  id: number;
  amount: number;
  discountType: PromotionDiscountType;
  type: PromotionType;
  startDate: string;
  endDate: string;
  quantity: number;
  applicableQuantity: number;
  y?: {
    quantity: number;
  };
}

export interface PromotionGroup {
  id: number;
  promotionId: number;
  xLineItemQuantities: LineItemQuantities[];
  yLineItemQuantities: LineItemQuantities[];
}

export interface LineItemQuantities {
  lineItemId: number;
  variantId: number;
  quantity: number;
  price: number;
}

export interface SimplePromotion {
  id: number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  type: PromotionType;
  discountType: PromotionDiscountType;
  amount: number;
  isAllProducts: number;
  isAllLocations: number;
  promotionVariants: number[];
  /**
   * API returns PromotionVariants with capital P
   */
  PromotionVariants: number[];
  PromotionStockLocations: number[];
}

export interface Promotion {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  type: PromotionType;
  discountType: PromotionDiscountType;
  amount: number;
  isAll: number;
  quantity: number;
  itemType: PromotionItemType;
  itemIds: number[];
  y?: PromotionY;
  actionType?: PromotionChangeType;

  /**
   * Simple Promo types, to be deprecated
   */

  isAllProducts?: number;
  isAllLocations?: number;
  promotionVariants?: number[];
  PromotionVariants?: {
    promotionId: number;
    productVariantId: number;
  }[];
  PromotionStockLocations?: {
    promotionId: number;
    stockLocationId: number;
  }[];
}

export type PromotionY = {
  isAll: number;
  itemType: PromotionItemType;
  itemIds?: number[];
  quantity: number;
};

export interface AppliedPromotionToVariant {
  [lineItemId: number]: boolean;
}

export interface ActiveAdvancePromotion {
  productSpecific?: Promotion[];
  categorySpecific?: Promotion[];
  forAllProducts: Promotion[];
}

export interface ActiveSimplePromotion {
  variantSpecific: SimplePromotion[];
  forAllProducts?: SimplePromotion;
}

export const enum PromotionItemType {
  Category = 'category',
  Product = 'product',
}

export const enum PromotionType {
  Simple = 'simple',
  Conditional = 'conditional',
}

export const PromotionTypeConstant = {
  Simple: 'simple',
  Conditional: 'conditional',
} as const;

export const enum PromotionDiscountType {
  Amount = 'amount',
  Percentage = 'percentage',
  Free = 'free_product',
}

export const PromotionDiscountTypeConstant = {
  Amount: 'amount',
  Percentage: 'percentage',
  Free: 'free_product',
} as const;

export const enum PromotionChangeType {
  Updated = 'Updated',
  Created = 'Created',
  Deleted = 'Deleted',
}

export interface InvoicePromotions {
  lineItemPromotions: LineItemPromotion[];
  promotionGroups: PromotionGroup[];
  promotions: Promotion[];
}
